/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Staff',
  components: {},
  props: {
    personlist: {
      type: Object,
      default: function _default() {}
    },
    depNameList: {
      type: Object,
      default: function _default() {}
    },
    fromPath: {
      type: String,
      default: function _default() {}
    },
    currentDepId: {
      type: Number,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    cardInfo: function cardInfo(personid) {
      this.$router.push({
        path: '/FileDetail',
        query: {
          personid: personid,
          //人员编号
          depNameList: this.depNameList,
          //当前的组织架构路径
          currentDepId: this.currentDepId //当前部门编号
          // fromPath:this.fromPath//从哪个页面跳转的

        }
      });
    }
  }
};