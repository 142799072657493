/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Staff from '@/components/Organization/Staff';
import Dep from '@/components/Organization/Dep';
export default {
  name: 'organizationList',
  components: {
    Staff: Staff,
    Dep: Dep
  },
  props: {},
  data: function data() {
    return {
      depid: '',
      depNameList: [],
      personList: [{
        personName: '张三',
        personPsot: '部门负责人',
        id: '001'
      }, {
        personName: '李四',
        personPsot: '部门主管',
        id: '002'
      }],
      depList: [{
        depName: '销售内勤',
        personNum: 1,
        id: '0011'
      }, {
        depName: '销售部',
        personNum: 5,
        id: '0012'
      }, {
        depName: '市场部',
        personNum: 36,
        id: '0013'
      }, {
        depName: '售后服务部',
        personNum: 54,
        id: '0014'
      }],
      currentDep: '',
      currentPath: '/organizationList',
      path: ''
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.orgback, false);
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    //在next中写处理函数
    next(function (vm) {
      return vm.where(from);
    });
  },
  destroyed: function destroyed() {
    window.removeEventListener('popstate', this.orgback, false);
  },
  methods: {
    where: function where(from) {
      // 从公司总架构跳转过来
      if (from.name == 'fileList') {
        //将公司名称存入数组第一位
        this.depNameList.push({
          depid: '-1',
          depName: this.$route.query.companyName
        }); //将点击的部门信息存入数组

        this.depNameList.push({
          depid: this.$route.query.depid,
          depName: this.$route.query.depName
        }); // 设置当前的部门id

        this.currentDepId = this.$route.query.depid; //根据当前的部门id查询该部门信息
        //
        //
      } else if (from.name == 'FileDetail') {
        //从档案详情跳转过来
        this.depNameList = JSON.parse(this.$route.query.depNameList);
        this.currentDepId = this.$route.query.backdepId; //根据当前的部门id查询该部门信息
        //
        //
      } else if (from.name == 'organizationList') {
        //从本页跳转过来
        this.currentDepId = this.$route.query.currentDepId;
        this.depNameList = this.$route.query.depNameList; // 根据当前页面部门id查询信息并渲染
        //
        //
      }
    },
    orgback: function orgback() {
      if (this.depNameList.length == 2) {
        //返回上一页为公司总架构
        this.$router.push({
          path: '/fileList'
        });
      } else {
        //返回上一级部门
        //将数组最后一个元素，即当前的部门元素移除
        this.depNameList.pop();
        this.$router.push({
          path: '/organizationList',
          query: {
            currentDepId: this.backDep,
            depNameList: this.depNameList
          }
        });
      }
    },
    //点击组织架构部门中的下属部门
    depdetail: function depdetail(item) {
      this.backDep = this.currentDep; //预返回为当前部门

      this.currentDep = item.id; //将点击的部门id存为当前的部门id

      this.depNameList.push({
        depid: item.id,
        depName: item.depName
      }); //将点击的部门信息存入数组
      //查询点击部门的信息并渲染
      //
      //

      this.personList = [{
        personName: '韩梅梅',
        personPsot: '部门负责人',
        id: '003'
      }];
      this.depList = [{
        depName: 'A部门',
        personNum: 41,
        id: '0021'
      }, {
        depName: 'B部门',
        personNum: 25,
        id: '0022'
      }, {
        depName: 'C部门',
        personNum: 16,
        id: '0023'
      }, {
        depName: 'D部门',
        personNum: 4,
        id: '0024'
      }];
    }
  }
};